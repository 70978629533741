<template>
  <div class="card">
    <form-wizard
      color="#BB5023"
      :title="null"
      :subtitle="null"
      shape="circle"
      finish-button-text="Submit"
      back-button-text="Previous"
      class="mb-3"
      @on-complete="formSubmitted"
    >
      <!-- Business Details tab -->
      <tab-content
        title="Entity Details"
        :before-change="validationForm"
      >
        <validation-observer
          ref="accountRules"
          tag="form"
        >
          <b-row class="p-lg-1">
            <div v-if="hasSelectedLicenseType">
              <p>
                Application for
                <strong>{{ addLicenseBusinessType }}</strong> license
              </p>
            </div>
            <b-col
              v-else
              cols="12"
            >
              <b-form-group
                label="Select Entity Type"
                label-for="selectBusinessType"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Business Type"
                  rules="required"
                >
                  <b-form-radio
                    v-model="addLicenseBusinessType"
                    plain
                    name="some-radios2"
                    value="Broadcaster"
                    @change="clearSelectedTariffCategory"
                  >
                    Broadcaster
                  </b-form-radio>
                  <b-form-radio
                    v-model="addLicenseBusinessType"
                    plain
                    name="some-radios1"
                    value="Business"
                    @change="clearSelectedTariffCategory"
                  >
                    Other Businesses
                  </b-form-radio>
                  <b-form-radio
                    v-model="addLicenseBusinessType"
                    plain
                    name="some-radios3"
                    value="Public Service Vehicle"
                    @change="clearSelectedTariffCategory"
                  >
                    Public Service Vehicle
                  </b-form-radio>
                  <small
                    v-if="errors[0]"
                    class="text-danger"
                  >Please select your entity type</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row v-if="addLicenseBusinessType != 'Public Service Vehicle'">
            <b-col
              cols="12"
              class="mb-2"
            >
              <h5 class="mb-0">
                Business Details
              </h5>
              <small class="text-muted"> Enter Your Business Details. </small>
            </b-col>
            <b-col
              v-if="userBusinesses.length"
              lg="5"
              md="6"
              sm="12"
            >
              <b-row>
                <b-col
                  lg="6"
                  md="6"
                  sm="12"
                >
                  <p>Application for new entity?</p>
                </b-col>
                <b-col
                  lg="4"
                  md="6"
                  sm="12"
                >
                  <div class="d-flex justify-content-around flex-md-row">
                    <b-form-radio
                      v-model="isNewBusiness"
                      plain
                      name="yes"
                      value="yes"
                    >
                      Yes
                    </b-form-radio>
                    <b-form-radio
                      v-model="isNewBusiness"
                      plain
                      name="no"
                      value="no"
                    >
                      No
                    </b-form-radio>
                  </div>
                </b-col>
              </b-row>
            </b-col>
            <b-col
              v-if="userBusinesses.length"
              lg="7"
              md="6"
              sm="12"
            >
              <div
                v-if="isNewBusiness === 'no'"
                class=""
              >
                <b-row>
                  <b-col
                    lg="3"
                    md="6"
                    sm="12"
                  >
                    <label
                      class="mr-1"
                    >Select Entity</label>
                  </b-col>
                  <b-col
                    lg="7"
                    md="6"
                    sm="12"
                  >
                    <b-form-group>
                      <b-form-select
                        v-model="selectedBusiness"
                        :options="userBusinesses"
                        size="sm"
                        @change="getSelectedBusiness"
                      />
                    </b-form-group>
                  </b-col>
                </b-row>
              </div>
            </b-col>
            <b-col
              md="6"
              sm="12"
            >
              <b-form-group
                label="Business Name"
                label-for="business name"
              >
                <validation-provider
                  #default="{ errors }"
                  name="business name"
                  rules="required"
                >
                  <b-form-input
                    id="business name"
                    v-model="addLicenseBusinessName"
                    :state="errors.length > 0 ? false : null"
                    placeholder="Enter Business Name"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col
              md="6"
              sm="12"
            >
              <b-form-group
                v-if="!useIdNumber"
                label="Business Registration Number"
                label-for="registration-number"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Registration Number"
                  :rules="{
                    required:
                      !addLicenseProprietorIdNumber && useIdNumber == false,
                  }"
                >
                  <b-form-input
                    id="registration-number"
                    v-model="addLicenseBusinessRegistrationNumber"
                    :state="errors.length > 0 ? false : null"
                    placeholder="Enter Business Registration Number"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <b-form-group
                v-if="useIdNumber"
                label="Proprietor ID Number"
                label-for="proprietor-id-number"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Proprietor ID Number"
                  :rules="{
                    required:
                      !addLicenseBusinessRegistrationNumber &&
                      useIdNumber == true,
                  }"
                >
                  <b-form-input
                    id="proprietor-id-number"
                    v-model="addLicenseProprietorIdNumber"
                    :state="errors.length > 0 ? false : null"
                    placeholder="Enter Business Proprietor ID Number"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <b-row>
                <b-col
                  lg="8"
                  md="7"
                  sm="12"
                >
                  <p>Don't have a registration number. Use ID instead?</p>
                </b-col>
                <b-col
                  lg="4"
                  md="5"
                  sm="12"
                >
                  <div class="d-flex justify-content-around flex-md-row">
                    <b-form-radio
                      v-model="useIdNumber"
                      plain
                      name="true"
                      :value="true"
                    >
                      Yes
                    </b-form-radio>
                    <b-form-radio
                      v-model="useIdNumber"
                      plain
                      name="false"
                      :value="false"
                    >
                      No
                    </b-form-radio>
                  </div>
                </b-col>
              </b-row>
            </b-col>
            <b-col
              md="6"
              sm="12"
            >
              <b-form-group
                label="KRA PIN"
                label-for="kra pin"
              >
                <validation-provider
                  #default="{ errors }"
                  name="KRA PIN"
                  rules="required|validate-kra-pin"
                >
                  <b-form-input
                    id="kra pin"
                    v-model="addLicenseBusinessKraPin"
                    :state="errors.length > 0 ? false : null"
                    placeholder="Enter Business KRA PIN"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <!-- <b-col md="6" sm="12">
              <b-form-group
                label="Select the business area of Operation/Jurisdiction"
                label-for="selectCounties"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Business Jurisdiction"
                  rules="required"
                >
                  <v-select
                    v-model="addLicenseBusinessJurisdiction"
                    :options="counties"
                    multiple
                    taggable
                    :selectable="
                      () =>
                        addLicenseBusinessJurisdiction.includes('National') < 1
                    "
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
                <div class="mt-1">
                  Selected:
                  <strong>{{ addLicenseBusinessJurisdiction }}</strong>
                </div>
              </b-form-group>
            </b-col> -->
            <b-col
              md="6"
              sm="12"
            >
              <b-form-group
                label="Business Phone Number"
                label-for="business phone number"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Business Phone Number"
                  rules="required"
                >
                  <b-form-input
                    id="business-phone-number"
                    v-model="addLicenseBusinessPhoneNumber"
                    :state="errors.length > 0 ? false : null"
                    placeholder="Enter Business Phone Number"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col
              md="6"
              sm="12"
            >
              <b-form-group
                label="Business Email"
                label-for="business email"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Business Email"
                  rules="required"
                >
                  <b-form-input
                    id="business-email"
                    v-model="addLicenseBusinessEmail"
                    :state="errors.length > 0 ? false : null"
                    placeholder="Enter Business Email"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row v-else>
            <b-col cols="12">
              <b-form-group
                v-if="addLicenseBusinessType === 'Public Service Vehicle'"
                label="Enter Sacco Name (or Your Name if Sacco does not apply)"
                label-for="sacco-name"
              >
                <validation-provider
                  #default="{ errors }"
                  name="PSV Sacco Name"
                  rules="required"
                >
                  <b-form-input
                    id="sacco-name"
                    v-model="addLicensePsvSaccoName"
                    :state="errors.length > 0 ? false : null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                  <b-form-group
                    label="Select the business area of Operation/Jurisdiction"
                    label-for="selectCounties"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Business Jurisdiction"
                      rules="required"
                    >
                      <v-select
                        v-model="addLicenseBusinessJurisdiction"
                        :options="counties"
                        multiple
                        taggable
                        :selectable="
                          () =>
                            addLicenseBusinessJurisdiction.includes(
                              'National'
                            ) < 1
                        "
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                    <div class="mt-1">
                      Selected:
                      <strong>{{ addLicenseBusinessJurisdiction }}</strong>
                    </div>
                  </b-form-group>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col cols="12">
              <b-form-group
                v-if="addLicenseBusinessType === 'Public Service Vehicle'"
                label="Enter Vehicle License Plate Number"
                label-for="license-plate-number"
              >
                <validation-provider
                  #default="{ errors }"
                  name="License Plate Number"
                  rules="required"
                >
                  <b-form-input
                    id="license-plate-number"
                    v-model="addLicensePsvLicensePlateNumber"
                    :state="errors.length > 0 ? false : null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
        </validation-observer>
      </tab-content>
      <!-- Business Location Tab -->
      <tab-content
        v-if="addLicenseBusinessType !== 'Public Service Vehicle'"
        title="Business Location"
        :before-change="businessLocationValidation"
      >
        <validation-observer
          ref="businessLocation"
          tag="form"
        >
          <b-row v-if="addLicenseBusinessType != 'Public Service Vehicle'">
            <b-col
              cols="12"
              class="mb-2"
            >
              <h5 class="mb-0">
                Business Location Details
              </h5>
              <small class="text-muted"> Enter Your Business Location Details. </small>
            </b-col>
            <b-col
              md="6"
              sm="12"
            >
              <b-form-group
                label="Select the county"
                label-for="enterCounty"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Business County"
                  rules="required"
                >
                  <b-form-group>
                    <b-form-select
                      v-model="addLicenseCounty"
                      :options="countiesOptions"
                      size="sm"
                      @change="getSelectedCounty"
                    />
                  </b-form-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col
              md="6"
              sm="12"
            >
              <b-form-group
                label="Enter the town"
                label-for="enterTown"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Business Location Town"
                  rules="required"
                >
                  <b-form-input
                    id="business town"
                    v-model="addLicenseTown"
                    :state="errors.length > 0 ? false : null"
                    placeholder="Enter Town"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col
              md="6"
              sm="12"
            >
              <b-form-group
                label="Enter the street name"
                label-for="enterStreetName"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Business Location Street Name"
                  rules="required"
                >
                  <b-form-input
                    id="business street name"
                    v-model="addLicenseStreetName"
                    :state="errors.length > 0 ? false : null"
                    placeholder="Enter Street Name"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col
              md="6"
              sm="12"
            >
              <b-form-group
                label="Enter the building name"
                label-for="enterBuildingName"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Building Name"
                  rules="required"
                >
                  <b-form-input
                    id="business building name"
                    v-model="addLicenseBuildingName"
                    :state="errors.length > 0 ? false : null"
                    placeholder="Enter Building Name"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
        </validation-observer>
      </tab-content>

      <!-- License details tab -->
      <tab-content
        title="License Details"
        :before-change="validationFormInfo"
      >
        <validation-observer
          ref="infoRules"
          tag="form"
        >
          <b-row>
            <b-col
              cols="12"
              class="mb-2"
            >
              <h5 class="mb-0">
                License Info.
              </h5>
              <small class="text-muted">Select your License Details.</small>
            </b-col>
            <b-col
              lg="6"
              md="6"
              sm="12"
            >
              <b-form-group
                label="Select Tariff Category"
                label-for="selectBusinessType"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Tariff Category"
                  rules="required"
                >
                  <b-form-select
                    v-model="addLicenseBusinessTariffCategory"
                    :options="tariffs"
                    @change="getSelectedTariffCategory"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col
              lg="6"
              md="6"
              sm="12"
            >
              <div v-if="tariffHasRates">
                <b-row
                  v-for="item in tariffDocumentItems"
                  :key="item.id"
                >
                  <b-col
                    lg="6"
                    sm="12"
                  >
                    <b-form-group
                      :label="'Enter ' + item.rated_item"
                      :label-for="item.rated_item"
                    >
                      <validation-provider
                        #default="{ errors }"
                        :name="item.rated_item"
                      >
                        <b-form-input
                          :id="item.rated_item"
                          v-model="addLicenseRatedItems[item.rated_item]"
                          :state="errors.length > 0 ? false : null"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>

                  <b-col
                    v-if="item.document_required"
                    lg="6"
                    sm="12"
                  >
                    <b-form-group :label="'Upload ' + item.document_required">
                      <validation-provider
                        #default="{ errors }"
                        :name="item.document_required"
                      >
                        <b-form-file
                          v-model="
                            addLicenseRatedDocuments[item.document_required]
                          "
                          placeholder="Choose a file or drop it here..."
                          drop-placeholder="Drop file here..."
                          accept=".pdf,.doc,.docx"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                </b-row>
              </div>
              <div v-else>
                <b-form-group
                  label="Select Items that you want to have licensed"
                  label-for="selectItems"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Items for Licensing"
                    rules="required"
                  >
                    <div
                      v-if="addLicenseBusinessType === 'Public Service Vehicle'"
                    >
                      <b-form-radio
                        v-for="item in tariffItems"
                        :key="item.value"
                        v-model="addLicenseItems"
                        :value="item.value"
                        plain
                      >
                        {{ item.text }}
                      </b-form-radio>
                    </div>
                    <div v-else>
                      <b-form-checkbox
                        v-for="item in tariffItems"
                        :key="item.value"
                        v-model="addLicenseItems"
                        :value="item.value"
                        plain
                      >
                        {{ item.text }}
                      </b-form-checkbox>
                    </div>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </div>
            </b-col>
          </b-row>
        </validation-observer>
      </tab-content>

      <!-- Invoice  -->
      <tab-content
        title="Invoice and Payment"
        :before-change="validationFormAddress"
      >
        <validation-observer
          ref="addressRules"
          tag="form"
        >
          <b-row>
            <b-col
              cols="12"
              class="mb-2"
            >
              <h5 class="mb-0">
                Invoice and Payment
              </h5>
              <small class="text-muted">Select Preferred Payment Method.</small>
            </b-col>
            <b-col
              cols="12"
              xl="9"
              md="8"
            >
              <div v-if="addLicenseBusinessType != 'Public Service Vehicle'">
                <b-card
                  v-if="invoiceData"
                  no-body
                  class="invoice-preview-card"
                >
                  <!-- Header -->
                  <b-card-body class="invoice-padding pb-0">
                    <div
                      class="d-flex justify-content-between flex-md-row flex-column invoice-spacing mt-0"
                    >
                      <!-- Header: Left Content -->
                      <div>
                        <div class="logo-wrapper">
                          <h1 class="text-primary">
                            {{ invoiceData.licenses[0].business_name }}
                          </h1>
                        </div>
                        <h5 class="text-primary">
                          {{ invoiceData.licenses[0].business_type }}
                        </h5>
                        <div class="d-none">
                          <p
                            v-for="jurisdiction in invoiceData.licenses[0]
                              .jurisdiction"
                            :key="jurisdiction"
                            class="card-text mb-25 mr-1"
                          >
                            {{ jurisdiction }},
                          </p>
                        </div>
                      </div>

                      <!-- Header: Right Content -->
                      <div class="mt-md-0 mt-2">
                        <h4 class="invoice-title">
                          Invoice
                          <span
                            class="invoice-number"
                          >#{{ invoiceData.invoice_id }}</span>
                        </h4>
                        <div class="invoice-date-wrapper justify-content-end">
                          <p class="invoice-date-title">
                            Date Issued:
                          </p>
                          <p class="invoice-date">
                            {{
                              moment(invoiceData.created_at).format(
                                "dddd, MMMM Do YYYY"
                              )
                            }}
                          </p>
                        </div>
                      </div>
                    </div>
                  </b-card-body>

                  <!-- Spacer -->
                  <hr class="invoice-spacing">

                  <!-- Invoice Description: Table -->
                  <!-- <b-table
                    v-if="invoiceData != null"
                    :dark="true"
                    responsive
                    :items="invoiceData.licenses"
                    :fields="['Item', 'total']"
                  >
                    <template #cell(Item)="data">
                      <b-card-text class="font-weight-bold mb-25">
                        {{ data.item.tariff.name }}
                      </b-card-text>
                    </template>
                    <template #cell(total)="data">
                      <b-card-text class="text-nowrap">
                        {{ data.item.tariff.price }}
                      </b-card-text>
                    </template>
                  </b-table> -->

                  <div v-if="invoiceData.licenses.length">
                    <b-table
                      v-if="invoiceData.licenses[0].metadata === null"
                      :dark="true"
                      responsive
                      :items="invoiceData.licenses"
                      :fields="['Item', 'total']"
                    >
                      <template #cell(Item)="data">
                        <b-card-text class="font-weight-bold mb-25">
                          {{ data.item.tariff.name }}
                        </b-card-text>
                      </template>
                      <template #cell(total)="data">
                        <b-card-text class="text-nowrap">
                          {{
                            new Intl.NumberFormat().format(
                              data.item.tariff.price
                            )
                          }}
                        </b-card-text>
                      </template>
                    </b-table>

                    <b-table
                      v-else
                      :dark="true"
                      responsive
                      :items="getLicenseRatedItems(invoiceData.licenses)"
                      :fields="['Item', 'total']"
                    >
                      <template #cell(Item)="data">
                        <b-card-text class="font-weight-bold mb-25">
                          {{
                            data.item.rate_index +
                              " " +
                              data.item.rated_item_index
                          }}
                        </b-card-text>
                      </template>
                      <template #cell(total)="data">
                        <b-card-text class="text-nowrap">
                          {{
                            "Ksh." +
                              new Intl.NumberFormat().format(
                                data.item.amount_index
                              )
                          }}
                        </b-card-text>
                      </template>
                    </b-table>
                    <p v-if="invoiceData.licenses[0].metadata != null">
                      *The total cost is subject to a minimum fee of Ksh.{{
                        new Intl.NumberFormat().format(
                          invoiceData.licenses[0].tariff.price
                        )
                      }}
                    </p>
                  </div>

                  <!-- Invoice Description: Total -->
                  <b-card-body class="invoice-padding pb-0">
                    <b-row>
                      <!-- Col: Total -->
                      <b-col
                        cols="12"
                        md="12"
                        class="mt-md-6 d-flex justify-content-end"
                        order="1"
                        order-md="2"
                      >
                        <div class="invoice-total-wrapper">
                          <div
                            class="invoice-total-item d-flex justify-content-end"
                          >
                            <p class="invoice-total-title mr-1">
                              Subtotal:
                            </p>
                            <p class="invoice-total-amount font-weight-bold">
                              Ksh.
                              {{
                                new Intl.NumberFormat().format(
                                  getInvoiceTotalAmount(invoiceData)
                                )
                              }}
                            </p>
                          </div>
                          <hr class="my-50">
                          <div
                            class="invoice-total-item d-flex justify-content-end"
                          >
                            <p class="invoice-total-title mr-1">
                              Total:
                            </p>
                            <p class="invoice-total-amount font-weight-bold">
                              Ksh.
                              {{
                                new Intl.NumberFormat().format(
                                  getInvoiceTotalAmount(invoiceData)
                                )
                              }}
                            </p>
                          </div>
                        </div>
                      </b-col>
                    </b-row>
                  </b-card-body>
                  <b-button
                    variant="outline-secondary"
                    block
                    class="download-btn-card"
                    @click="downloadInvoice(invoiceData.invoice_id)"
                  >Download</b-button>
                </b-card>
              </div>
              <div v-else>
                <b-card
                  v-if="invoiceData"
                  no-body
                  class="invoice-preview-card"
                >
                  <!-- Header -->
                  <b-card-body class="invoice-padding pb-0">
                    <div
                      class="d-flex justify-content-between flex-md-row flex-column invoice-spacing mt-0"
                    >
                      <!-- Header: Left Content -->
                      <div>
                        <div class="logo-wrapper">
                          <h1 class="text-primary">
                            {{ invoiceData.psv_licenses[0].sacco_name }}
                          </h1>
                        </div>
                        <h5 class="text-primary">
                          {{
                            invoiceData.psv_licenses[0].car_license_plate_number
                          }}
                        </h5>
                      </div>

                      <!-- Header: Right Content -->
                      <div class="mt-md-0 mt-2">
                        <h4 class="invoice-title">
                          Invoice
                          <span
                            class="invoice-number"
                          >#{{ invoiceData.invoice_id }}</span>
                        </h4>
                        <div class="invoice-date-wrapper justify-content-end">
                          <p class="invoice-date-title">
                            Date Issued:
                          </p>
                          <p class="invoice-date">
                            {{
                              moment(invoiceData.created_at).format(
                                "dddd, MMMM Do YYYY"
                              )
                            }}
                          </p>
                        </div>
                      </div>
                    </div>
                  </b-card-body>

                  <!-- Spacer -->
                  <hr class="invoice-spacing">

                  <!-- Invoice Description: Total -->
                  <b-card-body class="invoice-padding pb-0">
                    <b-row>
                      <!-- Col: Total -->
                      <b-col
                        cols="12"
                        md="12"
                        class="mt-md-6 d-flex justify-content-end"
                        order="1"
                        order-md="2"
                      >
                        <div class="invoice-total-wrapper">
                          <div
                            class="invoice-total-item d-flex justify-content-end"
                          >
                            <p class="invoice-total-title mr-1">
                              Subtotal:
                            </p>
                            <p class="invoice-total-amount font-weight-bold">
                              Ksh.
                              {{
                                new Intl.NumberFormat().format(
                                  getInvoiceTotalAmount(invoiceData)
                                )
                              }}
                            </p>
                          </div>
                          <hr class="my-50">
                          <div
                            class="invoice-total-item d-flex justify-content-end"
                          >
                            <p class="invoice-total-title mr-1">
                              Total:
                            </p>
                            <p class="invoice-total-amount font-weight-bold">
                              Ksh.
                              {{
                                new Intl.NumberFormat().format(
                                  getInvoiceTotalAmount(invoiceData)
                                )
                              }}
                            </p>
                          </div>
                        </div>
                      </b-col>
                    </b-row>
                  </b-card-body>
                  <b-button
                    variant="outline-secondary"
                    block
                    class="download-btn-card"
                    @click="downloadInvoice(invoiceData.invoice_id)"
                  >Download</b-button>
                </b-card>
              </div>
            </b-col>
            <b-col
              cols="12"
              md="4"
              xl="3"
              class="invoice-actions"
            >
              <b-form-group
                label="Select Payment Method"
                label-for="selectPaymentMethod"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Payment Method"
                  rules="required"
                >
                  <b-form-radio
                    v-model="selectedPaymentMethod"
                    plain
                    name="some-radios3"
                    value="M-Pesa"
                  >
                    M-pesa
                  </b-form-radio>
                  <b-form-radio
                    v-model="selectedPaymentMethod"
                    plain
                    name="some-radios2"
                    value="Debit/Credit Card"
                  >
                    Debit/Credit Card
                  </b-form-radio>
                  <b-form-radio
                    v-model="selectedPaymentMethod"
                    plain
                    name="some-radios2"
                    value="Cheque"
                  >
                    Cheque
                  </b-form-radio>
                  <small
                    v-if="errors[0]"
                    class="text-danger"
                  >Please select one payment method</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
        </validation-observer>
      </tab-content>

      <!-- Payment -->
      <tab-content
        title="Payment Confirmation"
        :before-change="validationFormSocial"
      >
        <validation-observer
          ref="socialRules"
          tag="form"
        >
          <b-card
            v-if="selectedPaymentMethod === 'M-Pesa'"
            class="instructions-card"
          >Follow The Instructions Below <strong>or</strong> Enter Your Phone
            Number To Get Payment Prompt.</b-card>
          <b-row v-if="selectedPaymentMethod === 'M-Pesa'">
            <b-col
              lg="5"
              md="6"
              sm="12"
            >
              <b-card>
                <h5>Payment Instructions</h5>
                <ol>
                  <li>Go to Mpesa Menu on your phone.</li>
                  <li>Select Lipa na Mpesa</li>
                  <li>Select Paybill</li>
                  <li>
                    Enter Paybill No. <strong>884350</strong>, then press Ok
                  </li>
                  <li>
                    Enter Account No.
                    <strong>{{ invoiceData.invoice_id }}</strong>, then press Ok
                  </li>
                  <li>
                    Enter amount Ksh.
                    <strong>{{ getInvoiceTotalAmount(invoiceData) }}</strong>, then press Ok
                  </li>
                  <li>Enter Your PIN and press Ok</li>
                  <li>Confirm the transaction details</li>
                  <li>Wait for the MPESA transaction confirmation message</li>
                  <li>
                    Press the <strong>Submit</strong> button below to confirm
                    the transaction
                  </li>
                </ol>
              </b-card>
            </b-col>
            <b-col
              lg="5"
              md="6"
              sm="12"
            >
              <b-card>
                <validation-observer ref="refEnterPhoneNumberForm">
                  <ul>
                    <li>Enter Your Phone Number to get MPESA Prompt</li>
                  </ul>
                  <b-form-group
                    label="Enter Phone Number"
                    label-for="enterBusinessName"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Phone Number"
                      rules="required"
                    >
                      <b-form-input
                        id="enterTitle"
                        v-model="userPhoneNumber"
                        placeholder="Enter your phone number"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                  <ul>
                    <li>
                      Click Submit after receciving the MPESA confirmation
                      message.
                    </li>
                  </ul>
                  <b-button
                    class="float-right"
                    variant="primary"
                    @click="sendPaymentPrompt"
                  >
                    Send Prompt
                  </b-button>
                </validation-observer>
              </b-card>
            </b-col>
          </b-row>
          <b-row v-if="selectedPaymentMethod === 'Cheque'">
            <b-col
              lg="5"
              md="6"
              sm="12"
            >
              <b-card>
                <h5>Payment Instructions</h5>
                <ol>
                  <li>Enter the cheque details below and submit.</li>
                  <li>
                    Once the payment has been approved by our team, you will be
                    able to download yout license(s).
                  </li>
                </ol>
                <b-form-group label="Cheque Number">
                  <b-form-input
                    id="enterChequeNumber"
                    v-model="chequeNumber"
                    placeholder="Enter the cheque number"
                  />
                </b-form-group>
                <b-form-group label="Bank Code">
                  <b-form-input
                    id="bankCode"
                    v-model="bankCode"
                    placeholder="Enter the bank code"
                  />
                </b-form-group>
                <b-form-group label="Account Number">
                  <b-form-input
                    id="enterAccountNumber"
                    v-model="accountNumber"
                    placeholder="Enter the account number"
                  />
                </b-form-group>
              </b-card>
            </b-col>
          </b-row>
          <b-card v-if="selectedPaymentMethod === 'Debit/Credit Card'">
            Please wait while we process the payment
          </b-card>
        </validation-observer>
      </tab-content>
    </form-wizard>
  </div>
</template>

<script>
import { FormWizard, TabContent } from 'vue-form-wizard'
import vSelect from 'vue-select'
import { ValidationProvider, ValidationObserver, extend } from 'vee-validate'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'
import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormRadio,
  BFormCheckbox,
  BFormSelect,
  BCard,
  BCardBody,
  BCardText,
  BTable,
  BButton,
  BFormFile,
} from 'bootstrap-vue'
import { required, required_if } from '@validations'

extend('validate-kra-pin', {
  validate: (value => {
    let pass = true
    const onlyLetters = /^[a-zA-Z]+$/
    // eslint-disable-next-line camelcase
    const first_is_letter = value.charAt(0)
    // eslint-disable-next-line camelcase
    const last_is_letter = value.charAt(value.length - 1)
    // Check length
    if (value.length < 11 || value.length > 11) {
      pass = false
    } else {
      // Check if first character is letter
      // eslint-disable-next-line camelcase, no-lonely-if
      if (onlyLetters.test(first_is_letter)) {
        pass = true
        // Check if last character is letter
        if (onlyLetters.test(last_is_letter)) {
          pass = true
        } else {
          pass = false
        }
      } else {
        pass = false
      }
    }
    return pass
  }),
})

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    FormWizard,
    TabContent,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormRadio,
    BFormSelect,
    BFormCheckbox,
    vSelect,
    BCard,
    BCardBody,
    BCardText,
    BTable,
    BButton,
    BFormFile,

    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
    // eslint-disable-next-line vue/no-unused-components
    required,
    // eslint-disable-next-line vue/no-unused-components
    required_if,
  },
  data() {
    return {
      counties: ['National'],
      business_types: ['Public Service Vehicle', 'Broadcaster', 'Business'],
      isLoading: false,
      tariffCategories: [],
      tariffs: [],
      tariffItems: [],
      tariffDocumentItems: [],
      addLicenseBusinessName: '',
      addLicenseBusinessRegistrationNumber: '',
      addLicenseBusinessKraPin: '',
      addLicenseBusinessJurisdiction: [],
      addLicenseBusinessType: 'Broadcaster',
      addLicenseBusinessTariffCategory: '',
      addLicenseItems: [],
      addLicenseRatedItems: [],
      addLicenseRatedDocuments: [],
      addLicensePsvLicensePlateNumber: '',
      addLicensePsvSaccoName: '',
      addLicenseCounty: '',
      addLicenseTown: '',
      addLicenseStreetName: '',
      addLicenseBuildingName: '',
      addLicenseProprietorIdNumber: '',
      addLicenseBusinessPhoneNumber: '',
      addLicenseBusinessEmail: '',

      addLicenseBuinessPermitCost: '',
      addLicenseBusinessPermitFile: null,
      addLicenseLiqourLicenseCost: '',
      addLicenseLiqourLicenseFile: null,

      tariffHasRates: false,

      hasSelectedLicenseType: false,
      useIdNumber: false,

      invoiceData: null,
      userPhoneNumber: '',
      selectedPaymentMethod: '',

      chequeNumber: '',
      bankCode: '',
      accountNumber: '',

      isNewBusiness: 'yes',
      selectedBusiness: null,
      allBusinesses: [],
      userBusinesses: [],

      allCounties: [],
      countiesOptions: [],
      subcountiesOptions: [],
    }
  },
  watch: {
    isNewBusiness(currentValue) {
      if (currentValue === 'yes') {
        this.addLicenseBusinessName = ''
        this.addLicenseBusinessRegistrationNumber = ''
        this.addLicenseBusinessKraPin = ''
        this.addLicenseBusinessJurisdiction = []
        this.addLicenseProprietorIdNumber = null
        this.addLicenseBusinessPhoneNumber = ''
        this.addLicenseBusinessEmail = ''
        this.useIdNumber = false
      }
    },
  },
  created() {
    if (
      this.$router.currentRoute.params.type
      && this.$router.currentRoute.params.type === 'Public Service Vehicle'
    ) {
      this.addLicenseBusinessType = 'Public Service Vehicle'
      this.hasSelectedLicenseType = true
    } else if (
      this.$router.currentRoute.params.type
      && this.$router.currentRoute.params.type === 'Broadcasting'
    ) {
      this.addLicenseBusinessType = 'Broadcaster'
      this.hasSelectedLicenseType = true
    } else if (
      this.$router.currentRoute.params.type
      && this.$router.currentRoute.params.type === 'Business'
    ) {
      this.addLicenseBusinessType = 'Business'
      this.hasSelectedLicenseType = true
    } else {
      this.hasSelectedLicenseType = false
    }
  },
  mounted() {
    this.$http.get('/counties').then(response => {
      this.allCounties = response.data
      response.data.forEach(county => {
        this.counties.push(county.name)
        this.countiesOptions.push({
          value: county.id,
          text: county.name,
        })
      })
    })
    this.$http.get('/dashboard/data').then(response => {
      this.allBusinesses = response.data.data.businesses
      response.data.data.businesses.forEach(business => {
        this.userBusinesses.push({
          value: business.id,
          text: business.name,
        })
      })
    })
  },
  methods: {
    clearSelectedTariffCategory() {
      this.addLicenseBusinessTariffCategory = ''
      this.tariffs = []
      this.tariffItems = []
      this.tariffDocumentItems = []
      this.addLicenseBusinessJurisdiction = []
    },
    getSelectedTariffCategory(event) {
      this.tariffItems = []
      this.tariffDocumentItems = []
      this.addLicenseItems = []
      this.tariffCategories.forEach(category => {
        if (category.id === event) {
          if (category.rates.length) {
            this.tariffDocumentItems = category.rates
            this.addLicenseItems.push(category.tariffs[0].tariff_code)
            this.tariffHasRates = true
          } else {
            this.tariffHasRates = false
            this.tariffDocumentItems = []
            category.tariffs.forEach(tariff => {
              this.tariffItems.push({
                value: tariff.tariff_code,
                text: tariff.name,
              })
            })
          }
        }
      })
    },
    getInvoiceTotalAmount(invoice) {
      let totalAmount = 0
      if (this.addLicenseBusinessType !== 'Public Service Vehicle') {
        invoice.licenses.forEach(license => {
          if (license.metadata !== null) {
            let rate = 0
            const result = Object.keys(JSON.parse(license.metadata)).map(
              key => [key, JSON.parse(license.metadata)[key]],
            )
            // eslint-disable-next-line no-restricted-syntax, guard-for-in
            for (const data in result[0][1]) {
              totalAmount += Number(result[0][1][data])
              if (Number(result[0][1][data] !== 0)) {
                rate = license.tariff.tariff_category.rates.find(
                  // eslint-disable-next-line no-shadow
                  rate => rate.rated_item === data,
                ).cumulative_rate
              }
            }
            // eslint-disable-next-line operator-assignment
            totalAmount = rate * totalAmount
            if (totalAmount < license.tariff.price) {
              totalAmount = license.tariff.price
            }
          } else {
            totalAmount += license.tariff.price
          }
        })
      } else {
        invoice.psv_licenses.forEach(license => {
          totalAmount += license.tariff.price
        })
      }

      return totalAmount
    },
    getLicenseRatedItems(licenses) {
      // eslint-disable-next-line camelcase
      const rated_items = []
      licenses.forEach(license => {
        const result = Object.keys(JSON.parse(license.metadata)).map(key => [
          key,
          JSON.parse(license.metadata)[key],
        ])
        let amount = 0
        let rate = 0
        // eslint-disable-next-line camelcase
        let rated_item = ''
        let index = 0
        // eslint-disable-next-line no-restricted-syntax, guard-for-in
        for (const data in result[0][1]) {
          // eslint-disable-next-line camelcase
          rated_item = license.tariff.tariff_category.rates.find(
            // eslint-disable-next-line no-shadow
            rate => rate.rated_item === data,
          ).description
          rate = license.tariff.tariff_category.rates.find(
            // eslint-disable-next-line no-shadow
            rate => rate.rated_item === data,
          ).cumulative_rate
          if (rate <= 1) {
            rate *= 100
            rate = `${String(rate)}%`
          } else {
            rate = `Ksh.${String(rate)}`
          }
          amount += Number(result[0][1][data])
          index = {
            rated_item_index: rated_item,
            rate_index: rate,
            amount_index: amount,
          }
          rated_items.push(index)
          // eslint-disable-next-line no-plusplus
          index++
        }
      })
      return rated_items.slice(-1)
    },
    sendPaymentPrompt() {
      return new Promise((resolve, reject) => {
        this.$refs.refEnterPhoneNumberForm.validate().then(success => {
          if (success) {
            this.$http
              .post('/invoice/pay', {
                invoice_id: this.invoiceData.invoice_id,
                phone_number: this.userPhoneNumber,
              })
              .then(response => {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: response.data.data,
                    icon: 'CoffeeIcon',
                    variant: 'success',
                  },
                })
                resolve(response)
              })
              .catch(error => {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: error.response.data.data,
                    icon: 'AlertTriangleIcon',
                    variant: 'danger',
                  },
                })
                reject(error)
              })
          }
        })
      })
    },
    getSelectedBusiness(event) {
      this.allBusinesses.forEach(business => {
        if (event === business.id) {
          this.addLicenseBusinessName = business.name
          if (business.registration_number) {
            this.addLicenseBusinessRegistrationNumber = business.registration_number
            this.useIdNumber = false
          } else {
            this.addLicenseProprietorIdNumber = business.proprietor_id_number
            this.useIdNumber = true
          }
          this.addLicenseBusinessKraPin = business.kra_pin
          this.addLicenseBusinessJurisdiction = business.jurisdiction
          this.addLicenseBusinessPhoneNumber = business.business_phone_number
          this.addLicenseBusinessEmail = business.business_email
        }
      })
    },
    getSelectedCounty(event) {
      this.subcountiesOptions = []
      this.allCounties.forEach(county => {
        if (event === county.id) {
          county.subcounties.forEach(subcounty => {
            this.subcountiesOptions.push({
              value: subcounty.id,
              text: subcounty.name,
            })
          })
        }
      })
    },
    downloadInvoice(id) {
      return new Promise((resolve, reject) => {
        this.$http({
          url: `/invoice/${id}/download`,
          responseType: 'arraybuffer',
          method: 'GET',
        })
          .then(response => {
            const fileURL = window.URL.createObjectURL(new Blob([response.data]))
            const fileLink = document.createElement('a')

            fileLink.href = fileURL
            fileLink.setAttribute(
              'download',
              `${this.invoiceData.invoice_id}.pdf`,
            )
            document.body.appendChild(fileLink)

            fileLink.click()
            resolve(response)
          })
          .catch(error => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Error accessing file. Please try again.',
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            })
            reject(error)
          })
      })
    },
    formSubmitted() {
      switch (this.selectedPaymentMethod) {
        case 'M-Pesa':
          this.$http
            .post('/invoice/payment/status', {
              invoice_id: this.invoiceData.invoice_id,
            })
            .then(response => {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: response.data.message,
                  icon: 'CoffeeIcon',
                  variant: 'success',
                },
              })
              this.$router.replace({
                name: 'invoice-view',
                params: { id: this.invoiceData.invoice_id },
              })
            })
            .catch(error => {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: error.response.data.message,
                  icon: 'AlertTriangleIcon',
                  variant: 'danger',
                },
              })
            })
          break
        case 'Cheque':
          this.$http
            .post(`/invoice/${this.invoiceData.invoice_id}/cheque/save`, {
              cheque_number: this.chequeNumber,
              bank_code: this.bankCode,
              account_number: this.accountNumber,
            })
            .then(() => {
              this.$router.replace({
                name: 'invoice-view',
                params: { id: this.invoiceData.invoice_id },
              })
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Details updated successfully',
                  icon: 'CoffeeIcon',
                  variant: 'success',
                },
              })
            })
            .catch(() => {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Error saving cheque details',
                  icon: 'AlertTriangleIcon',
                  variant: 'danger',
                },
              })
            })
          break
        default:
          console.log('No action')
          break
      }
    },
    validationForm() {
      return new Promise((resolve, reject) => {
        this.$refs.accountRules.validate().then(success => {
          if (success) {
            this.isLoading = true
            if (this.addLicenseBusinessType === 'Public Service Vehicle') {
              this.$http
                .get(`/license/${this.addLicenseBusinessType}/tariffs`)
                .then(response => {
                  this.tariffs = []
                  this.tariffCategories = response.data.tariffs
                  response.data.tariffs.forEach(tariff => {
                    this.tariffs.push({ value: tariff.id, text: tariff.name })
                  })
                  resolve(true)
                })
                .catch(error => {
                  console.log(error.response)
                })
                .finally(() => {
                  this.isLoading = false
                })
            } else {
              resolve(true)
            }
            // Fetch tariffs based on selected business types
            // this.$http
            //   .get(`/license/${this.addLicenseBusinessType}/tariffs`)
            //   .then(response => {
            //     this.tariffs = []
            //     this.tariffCategories = response.data.tariffs
            //     response.data.tariffs.forEach(tariff => {
            //       this.tariffs.push({ value: tariff.id, text: tariff.name })
            //     })
            //     resolve(true)
            //   })
            //   .catch(error => {
            //     console.log(error.response)
            //   })
            //   .finally(() => {
            //     this.isLoading = false
            //   })
          } else {
            reject()
          }
        })
      })
    },
    businessLocationValidation() {
      return new Promise((resolve, reject) => {
        this.$refs.businessLocation.validate().then(success => {
          if (success) {
            this.isLoading = true
            // Fetch tariffs based on selected business types
            this.$http
              .get(`/license/${this.addLicenseBusinessType}/tariffs`)
              .then(response => {
                this.tariffs = []
                this.tariffCategories = response.data.tariffs
                response.data.tariffs.forEach(tariff => {
                  this.tariffs.push({ value: tariff.id, text: tariff.name })
                })
                resolve(true)
              })
              .catch(error => {
                console.log(error.response)
              })
              .finally(() => {
                this.isLoading = false
              })
          } else {
            reject()
          }
        })
      })
    },
    validationFormInfo() {
      return new Promise((resolve, reject) => {
        this.$refs.infoRules.validate().then(success => {
          if (success) {
            const formData = new FormData()
            formData.append('license_type', this.addLicenseBusinessType)
            formData.append('business_name', this.addLicenseBusinessName)
            formData.append(
              'registration_number',
              this.addLicenseBusinessRegistrationNumber,
            )
            formData.append('kra_pin', this.addLicenseBusinessKraPin)
            formData.append(
              'business_type',
              this.addLicenseBusinessTariffCategory,
            )
            formData.append(
              'license_items',
              JSON.stringify(this.addLicenseItems),
            )
            formData.append(
              'car_license_plate_number',
              this.addLicensePsvLicensePlateNumber,
            )
            formData.append('sacco_name', this.addLicensePsvSaccoName)
            formData.append('county', this.addLicenseCounty)
            formData.append('town', this.addLicenseTown)
            formData.append('street_name', this.addLicenseStreetName)
            formData.append('building_name', this.addLicenseBuildingName)
            formData.append(
              'business_phone_number',
              this.addLicenseBusinessPhoneNumber,
            )
            formData.append('business_email', this.addLicenseBusinessEmail)
            formData.append('business_name', this.addLicenseBusinessName)
            formData.append(
              'proprietor_id_number',
              this.addLicenseProprietorIdNumber,
            )
            formData.append('has_rated_items', this.tariffHasRates)

            if (this.tariffHasRates) {
              // eslint-disable-next-line no-restricted-syntax, guard-for-in
              for (const index in this.addLicenseRatedItems) {
                formData.append(
                  `rated_items[${index}]`,
                  this.addLicenseRatedItems[index],
                )
              }

              // eslint-disable-next-line no-restricted-syntax, guard-for-in
              for (const index in this.addLicenseRatedDocuments) {
                formData.append(
                  `rated_items_documents[${index}]`,
                  this.addLicenseRatedDocuments[index],
                )
              }
            }

            this.$http
              .post('/license/register', formData)
              .then(response => {
                this.invoiceData = response.data.data
                resolve(true)
              })
              .catch(error => {
                console.log(error.response)
              })
          } else {
            reject()
          }
        })
      })
    },
    validationFormAddress() {
      return new Promise((resolve, reject) => {
        this.$refs.addressRules.validate().then(success => {
          if (success) {
            switch (this.selectedPaymentMethod) {
              case 'M-Pesa':
                resolve(true)
                this.userPhoneNumber = JSON.parse(
                  localStorage.getItem('user'),
                ).phone_number
                break
              case 'Debit/Credit Card':
                resolve(true)
                this.$http
                  .post('invoice/jambopay/token', {
                    invoice_id: this.invoiceData.invoice_id,
                  })
                  .then(response => {
                    if (response.data.success) {
                      const checkoutDetails = {
                        OrderId: response.data.invoice.invoice_id,
                        CustomerEmail: response.data.invoice.user.email,
                        Currency: 'KES',
                        CustomerPhone: response.data.invoice.user.phone_number,
                        OrderAmount: response.data.amount,
                        BusinessEmail: 'martin@deveint.com',
                        CancelledUrl: 'invoice/jambopay/failed/callback',
                        CallBackUrl: 'invoice/jambopay/success/callback',
                        Description: 'Checkout description',
                        SupportPhone: '0727429744',
                        StoreName: 'PRISK',
                        ClientKey: response.data.client_key,
                      }

                      // eslint-disable-next-line no-undef
                      jambopayCheckout(checkoutDetails, data => {
                        if (data.status === 'SUCCESS') {
                          this.$http
                            .post('invoice/jambopay/success/callback', {
                              invoice_id: data.order_id,
                              receipt: data.receipt,
                              amount: data.amount,
                            })
                            .then(() => {
                              this.$router.replace({
                                name: 'invoice-view',
                                params: { id: this.invoiceData.invoice_id },
                              })
                              this.$toast({
                                component: ToastificationContent,
                                props: {
                                  title: 'Transaction successful',
                                  icon: 'CoffeeIcon',
                                  variant: 'success',
                                },
                              })
                            })
                        } else if (data.status === 'Failed') {
                          this.$http
                            .post('invoice/jambopay/failed/callback', {
                              invoice_id: this.invoiceData.invoice_id,
                            })
                            .then(res => {
                              this.$router.replace({
                                name: 'invoice-view',
                                params: { id: res.data.data.invoice_id },
                              })
                              this.$toast({
                                component: ToastificationContent,
                                props: {
                                  title: 'Transaction failed or was cancelled',
                                  icon: 'AlertTriangleIcon',
                                  variant: 'danger',
                                },
                              })
                            })
                        } else {
                          this.$http
                            .post('invoice/jambopay/failed/callback', {
                              invoice_id: this.invoiceData.invoice_id,
                            })
                            .then(res => {
                              this.$router.replace({
                                name: 'invoice-view',
                                params: { id: res.data.data.invoice_id },
                              })
                              this.$toast({
                                component: ToastificationContent,
                                props: {
                                  title: 'Transaction failed or was cancelled',
                                  icon: 'AlertTriangleIcon',
                                  variant: 'danger',
                                },
                              })
                            })
                        }
                      })
                    }
                  })
                  .catch(error => {
                    console.log(error)
                  })
                break
              case 'Cheque':
                resolve(true)
                break
              default:
                break
            }
          } else {
            reject()
          }
        })
      })
    },
    validationFormSocial() {
      return new Promise((resolve, reject) => {
        this.$refs.socialRules.validate().then(success => {
          if (success) {
            resolve(true)
          } else {
            reject()
          }
        })
      })
    },
  },
}
</script>
<style lang="scss" scoped>
@import "~@core/scss/base/pages/app-invoice.scss";
</style>

<style scoped>
.settle-invoice-card {
  width: 50%;
}
.instructions-card {
  width: 75%;
}
.download-btn-card {
  width: 25%;
}
@media only screen and (max-width: 992px) {
  .settle-invoice-card {
    width: 100%;
  }
  .instructions-card {
    width: 100%;
  }
  .download-btn-card {
    width: 50%;
  }
}
@media only screen and (max-width: 768px) {
  .download-btn-card {
    width: 100%;
  }
}
</style>

<style lang="scss">
@media print {
  // Invoice Specific Styles
  .invoice-preview-wrapper {
    .row.invoice-preview {
      .col-md-8 {
        max-width: 100%;
        flex-grow: 1;
      }

      .invoice-preview-card {
        .card-body:nth-of-type(2) {
          .row {
            > .col-12 {
              max-width: 50% !important;
            }

            .col-12:nth-child(2) {
              display: flex;
              align-items: flex-start;
              justify-content: flex-end;
              margin-top: 0 !important;
            }
          }
        }
      }
    }

    // Action Right Col
    .invoice-actions {
      display: none;
    }
  }
}
</style>
